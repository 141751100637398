<template>
  <div v-if="$userInfo">
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-new="$user.hasAdd($route.meta.id)"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <purchase-order-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(entity, index) in entitySummaries"
                :key="entity.purchaseOrderId"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td><a @click.prevent="edit(entity.purchaseOrderId, $event)"
                  :href="generateUrl(entity.purchaseOrderId)">{{ entity.purchaseOrderNoRef }}</a></td>
                <td>{{ $filters.formatDateTimezone(entity.purchaseOrderDate, $userInfo.locale) }}</td>
                <td>{{ entity.quoteNoRef }}</td>
                <td v-if="entity.partsCheckPurchaseOrderNo === ''">Manual PO</td>
                <td v-else>{{ entity.partsCheckPurchaseOrderNo }}</td>
                <td>{{ entity.rego }}</td>
                <td>{{ entity.makeModel }}</td>
                <td>{{ entity.vendorName }}</td>
                <td>{{ entity.customerName }}</td>
                <td class="has-text-right">{{ entity.orderQty }}</td>
                <td class="has-text-right">{{ entity.receivedQty }}</td>
                <td class="has-text-right">{{ entity.returnQty }}</td>
                <td class="has-text-right">{{ $filters.formatCurrency(entity.totalAmount, $userInfo.locale) }}</td>
                <td class="has-text-centered">
                  <a class="button is-primary is-small is-inverted tooltip"
                    @click.prevent="edit(entity.purchaseOrderId, $event)"
                    :href="generateUrl(entity.purchaseOrderId)"
                    data-tooltip="Click to edit">
                    <span class="icon is-medium">
                      <i class="mdi mdi-lead-pencil mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PurchaseOrderListSideMenu from './PurchaseOrderListSideMenu'
import AppDetailHeader from '@/components/AppDetailHeader'
import PurchaseOrderService from './PurchaseOrderService'
import BulmaTable from '@/components/BulmaTable'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import { Columns } from './columns'
import { AppHeaderButtonTypes } from '@/enums'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { Emailer } from '@/classes'
import { KeyValuePairModel } from '@/classes/viewmodels'
import StoreMixin from './storeMixin'

export default {
  name: 'PurchaseOrderListView',
  components: {
    AppDetailHeader,
    [PurchaseOrderListSideMenu.name]: PurchaseOrderListSideMenu,
    BulmaTable
  },
  filters: {},
  mixins: [DateTimeFiltersMixin, StoreMixin],
  props: {},
  data() {
    return {
      entitySummaries: {},
      filter: {
        searchValue: '',
        sortColumn: Columns[1].name,
        sortOrder: Columns[1].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      },
      toggle: true,
      selectedRow: null,
      totalRows: 0,
      isTableLoading: false
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return Columns
    }
  },
  watch: {},
  created() {
    this.getData()
  },
  mounted() {},
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    generateUrl(id) {},
    edit(id, event) {
      this.$router.push({
        name: 'PurchaseOrderDetail',
        params: { purchaseOrderId: id }
      })
    },
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          this.addNew()
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    async getData() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.entitySummaries = await PurchaseOrderService.getEntitySummaries(this.filter)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      this.isTableLoading = false
    },
    filterList(value) {
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getData()
    },
    resetList() {
      this.filter.searchValue = ''
      this.filter.sortColumn = Columns[1].name
      this.filter.sortOrder = Columns[1].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.persistFilter()
      this.getData()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getData()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getData()
    },
    async addNew() {
      this.addStoreItem()
    },
    print() {
      let params = {
        CompanyID: this.$userInfo.companyId,
        SearchValue: this.filter.searchValue,
        SortColumn: this.filter.sortColumn,
        SortDirection: this.filter.sortOrder,
        PageIndex: 1,
        PageSize: this.totalRows,
        timezoneoffset: this.$filters.getSiteTimezoneOffset()
      }

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)
      const emailer = new Emailer()
      emailer.subject = 'Purchase Order Listing'
      emailer.reportName = 'PurchaseOrderList'
      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    }
  }
}
</script>
